import { Col, Container, Row } from 'react-bootstrap';
import styles from './App.module.scss';
import Sidebar from '../components/sidebar/Sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../home/Home';
import Helpdesk from '../helpdesk/Helpdesk';
import NewTicket from '../helpdesk/newTicket/NewTicket';
import Projects from '../projects/Projects';
import Tasks from '../tasks/Tasks';
import Wiki from '../wiki/Wiki';
import './App.css';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import Authorise from '../auth/Authorise';
import NewUser from '../administration/newUser/NewUser';
import Users from '../administration/users/Users';
import Reporting from '../administration/reporting/Reporting';
import Tickets from '../administration/tickets/Tickets';

/**
 * @description Component to render the routes for the application
 */
const AppRoutes = () => (
    <Routes>
        <Route path='/' Component={Home} />
        <Route path='/wiki' Component={Wiki} />
        <Route path='/helpdesk/ticket' Component={NewTicket} />
        <Route path='/helpdesk/ticket/*' Component={Helpdesk} />
        <Route path='/helpdesk/tickets/*' Component={Helpdesk} />
        <Route path='/projects/*' Component={Projects} />
        <Route path='/tasks/*' Component={Tasks} />
        <Route path='/administration/users' Component={Users} />
        <Route path='/administration/user' Component={NewUser} />
        <Route path='/administration/reporting' Component={Reporting} />
        <Route path='/administration/tickets' Component={Tickets} />
    </Routes>
);

/**
 * @description Component to render the application
 * @returns A React function component
 */
export default function App() {

    const { token, login, logout } = useAuth();
    const [refresh, setRefresh] = useState(false);

    const handleTokenUpdate = () => setRefresh(!refresh);

    useEffect(() => {}, [refresh]);

    if (!token) {
        return <Authorise login={login} onTokenUpdate={handleTokenUpdate} />;
    }

    const logoutAction = () => {
        logout();
        window.location.href = '/';
    }
    
    return (
        <BrowserRouter>
            <div className='App'>
                <Sidebar logout={logoutAction} />
                <main role="main" className={styles.mainContent}>
                    <Container fluid>
                        <Row>
                            <Col xs={12} id="page-content-wrapper">
                                <AppRoutes />
                            </Col>
                        </Row>
                    </Container>
                </main>
            </div>
        </BrowserRouter>
    );
}