import { Dispatch, SetStateAction, useEffect, useState } from "react";
import accountService from "../../services/AccountService";
import IAccount from "../../models/IAccount";
import IClient from "../../models/IClient"; // Assuming there's a model for IClient
import { Button, Card, Col, Container, Row, Alert, Collapse, Spinner } from "react-bootstrap";
import EditUserModal from "./modal/EditUserModal";
import Status from "../../enums/Status";
import clientService from "../../services/ClientService";
import AssignClientsModal from "./modal/AssignClientsModal";
import IUserClient from "../../interfaces/IUserClient";
import PageHeader from "../../components/pageHeader/PageHeader";
import { t } from "i18next";

export default function Users() {
    const [users, setUsers] = useState<IAccount[]>([]);
    const [clients, setClients] = useState<IClient[]>([]); // State for clients
    const [currentUser, setCurrentUser] = useState<IAccount | null>(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [userClients, setUserClients] = useState<IUserClient[]>([]); // State for user-client mappings

    // State to manage the open/close status of each client
    const [openClient, setOpenClient] = useState<string | null>(null);

    async function fetchUsersAndClients() {
        try {
            const usersResponse = await accountService.getAllAccounts();
            const clientsResponse = await clientService.getClients(); // Fetch clients
            const userClientsResponse = await accountService.getClientsForUsers(); // Fetch clients for users

            setUsers(usersResponse);
            setClients(clientsResponse);
            setUserClients(userClientsResponse);

            setStatus(Status.LOAD_SUCCESS);

        } catch (error) {
            setStatus(Status.LOAD_ERROR);
            setStatusMessage("Error fetching data. Please try again.");
        }
    }

    useEffect(() => {
        fetchUsersAndClients();
    }, []);

    const handleEditUser = (user: IAccount) => {
        setCurrentUser(user);
        setShowEditModal(true);
    };

    const handleSaveUser = (updatedUser: IAccount): void => {
        editUser(updatedUser, setStatus, setUsers, setStatusMessage);
    };

    const handleDeleteUser = (user: IAccount): void => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${user.username}?`
        );
        if (confirmDelete) {
            deleteUser(user, setStatus, setUsers);
        }
    };

    const handleAssignClients = (user: IAccount) => {
        setCurrentUser(user);
        setShowAssignModal(true);
    };

    const handleSaveClients = (selectedClients: number[]) => {
        accountService.assignClients(currentUser!.id, selectedClients);
        fetchUsersAndClients(); // Refetch users and clients after saving
    };

    // Group users by clientId
    const groupedUsers = users.reduce((acc, user) => {
        const { clientId } = user;
        if (!acc[clientId]) {
            acc[clientId] = [];
        }
        acc[clientId].push(user);
        return acc;
    }, {} as Record<string, IAccount[]>);

    if (status === Status.LOADING) {
        return (
            <>
            <PageHeader title={t("administration.users")} subtitle={t("administration.titles.hd")} />
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            </>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <>
            <PageHeader title={t("administration.users")} subtitle={t("administration.titles.hd")} />
                <Alert variant="danger">{t("helpdesk.exceptions.loadTickets")}</Alert>
            </>
        );
    }

    return (
        <>
        <PageHeader title={t("administration.users")} subtitle={t("administration.titles.hd")} />
        
        <Container>
            {Object.keys(groupedUsers).map((clientId) => {
                // Find the client name corresponding to the clientId
                const client = clients.find((client) => client.id.toString() === clientId);
                const clientName = client ? client.name : "Dolphin IT Solutions";

                return (
                    <Card key={clientId} style={{ marginBottom: '1rem' }}>
                        <Card.Header
                            onClick={() => setOpenClient(openClient === clientId ? null : clientId)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: openClient === clientId ? '#007bff' : '#fff',
                                color: openClient === clientId ? '#fff' : '#000',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h3 style={{ margin: 0 }}>{clientName}</h3>
                            <span style={{ fontSize: '1rem' }}>
                                {openClient === clientId ? '▲' : '▼'}
                            </span>
                        </Card.Header>
                        <Collapse in={openClient === clientId}>
                            <div>
                                <Card.Body>
                                    <Row>
                                        {groupedUsers[clientId].map((user) => (
                                            <Col xs={12} md={6} lg={4} key={user.id} className="mb-4">
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>{user.username}</Card.Title>
                                                        <Card.Text>
                                                            <strong>
                                                                {user.firstName} {user.lastName}
                                                                <br />
                                                            </strong>
                                                        </Card.Text>
                                                        <Button
                                                            variant="primary"
                                                            className="me-2"
                                                            onClick={() => handleEditUser(user)} >
                                                            Edit
                                                        </Button>
                                                        {user.clientId === 0 && (
                                                            <Button 
                                                                variant="primary" 
                                                                className="me-2"
                                                                onClick={() => handleAssignClients(user)}>
                                                                Assign Clients
                                                            </Button>
                                                        )}
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => handleDeleteUser(user)}>
                                                            Delete
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card.Body>
                            </div>
                        </Collapse>
                    </Card>
                );
            })}

            <EditUserModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                onSave={handleSaveUser}
                currentUser={currentUser}
            />

            <AssignClientsModal
                key={currentUser?.id} // Add this line
                show={showAssignModal}
                onHide={() => setShowAssignModal(false)}
                onSave={handleSaveClients}
                currentUser={currentUser}
                clients={clients}
                assignedClientIds={userClients.find(u => u.userId === currentUser?.id)?.clientIds || []}
            />
        </Container>
        </>
    );
}

function editUser(
    user: IAccount,
    setStatus: Dispatch<SetStateAction<Status>>,
    setUsers: Dispatch<SetStateAction<IAccount[]>>,
    setStatusMessage: Dispatch<SetStateAction<string>>
) {
    setStatus(Status.LOADING);

    accountService.updateUser(user)
        .then(() => {
            // Update the users list with the edited user
            setUsers((prevUsers) => 
                prevUsers.map((u) => (u.id === user.id ? user : u))
            );
            setStatus(Status.LOAD_SUCCESS);
            setStatusMessage("User updated successfully.");
        })
        .catch(() => {
            setStatus(Status.LOAD_ERROR);
            setStatusMessage("Error updating user. Please try again.");
        });
}

function deleteUser(
    user: IAccount,  // The user being deleted
    setStatus: Dispatch<SetStateAction<Status>>,
    setUsers: Dispatch<SetStateAction<IAccount[]>>
) {
    setStatus(Status.LOADING);

    accountService.deleteUser(user.id)
        .then(() => {
            // Update the users list to remove the deleted user
            setUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
            setStatus(Status.LOAD_SUCCESS);
            window.confirm("User deleted successfully.");
        })
        .catch(() => {
            setStatus(Status.LOAD_ERROR);
            window.confirm("Error deleting user. Please try again.");
        });
}